*,
canvas {
    cursor: none;
}

.cursor {
    pointer-events: none;
    position: fixed;
    display: block;
    border-radius: 0;
    top: 0;
    left: 0;
    z-index: 9999;
    /* mix-blend-mode: difference; */
}

/*

.cursor.link {
    mix-blend-mode: normal;
}

*/

/* .cursor.hoverEle_link .link {} */

.cursor>span {
    position: absolute;
    display: block;
    width: 12px;
    height: 12px;
    /* background-color: #470cdd; */
    background-color: #bdff5a;
    transform-origin: center center;
    transform: rotate(-45deg), translate(-50%, -50%);
    opacity: 1;
    transition: all 0.1s ease-out;
    /* transform: rotate(-45deg), translate(-50%, -50%); */
}


.cursor.link>span {
    opacity: 1;
    width: 18px;
    height: 18px;
    background-color: #fff;
    mix-blend-mode: overlay;

}

.cursor .cursor_link {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #3d3d3da2;
    width: 12px;
    height: 12px;
    opacity: 0;
    transform: rotate(-45deg);
    transition: all 0.1s ease-out;

}

/* 
.cursor.link .cursor_link {

    width: 48px;
    height: 48px;

    opacity: 1; 
}
*/

.cursor .cursor_link>span {
    display: block;
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.2rem;
    width: 100%;
    color: #000;
    transform: rotate(45deg);


}

.cursor .c-sq {
    width: 16px;
    height: 16px;
    background-color: #fefefe;
    transform: rotate(-45deg);
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    transform-origin: center center;
}