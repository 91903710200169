@import url('https://fonts.googleapis.com/css2?family=Kumar+One&family=Rajdhani:wght@300;400;500&display=swap');

:root {
    /*
    --primary: #470cdd; 
    --primary: #49f37f;
    */
    --primary: #bdff5a;
    --pDark: #273b08;
    --white: #ffffff;
}

* {
    margin: 0;
    padding: 0;
}

html,
body {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

body {
    padding: 10px;
    background-color: #111111;
    font-family: 'Rajdhani';
    font-weight: 300;
    padding: 10px;
    overscroll-behavior: none;
}

main {
    position: relative;
    width: 100%;
    height: 100%;
    border: 5px solid var(--primary);
    box-sizing: border-box;
}

main.work {
    position: fixed;
    top: 10px;
    left: 10px;
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
}

.container {
    width: 100%;
    height: 100%;
}


nav {
    position: fixed;
    display: flex;
    flex-direction: row;
    right: 20px;
    top: 10px;
    z-index: 99;
}

nav a.navLink {
    color: rgb(226, 226, 226);
    font-weight: 400;
    font-size: 18px;
    text-decoration: none;
    padding: 18px 18px;
    text-transform: uppercase;
    letter-spacing: 0.08rem;
}

.webgl {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    /* z-index: 2; */
}

.heading {
    position: absolute;
    top: 30%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);

}

.heading h2,
.heading span {
    font-family: 'Kumar One';
    color: #e47a28;
    color: #0cdd25;
    color: var(--primary);

    z-index: 1;
    font-size: 5vw;
    line-height: 7vw;

    font-weight: 300;

    transform-origin: center;
    text-align: center;
}

.heading.l2 {
    top: 75%;
    z-index: 4;
}


/* ----------------------- about ------------------------- */

main.work {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.wrapper_about {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    padding: 7vh 5vw;
    box-sizing: border-box;
}

.about_section {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.about_section .about_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;


}

.about_section .about_content .about_block {
    display: inline-block;
    position: relative;
    width: 54%;
}

.about_section .about_content .about_block:last-child {
    width: 38%;
}

.about_section .about_content .about_block h3 {
    font-size: 1.6rem;
    color: #fff;
}

.about_section .about_content .about_block .associated_wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;

    width: 100%;
    padding-top: 18px;
}

.about_section .about_content .about_block .associated_wrapper img {
    display: inline-block;
    padding: 18px 32px;
    width: calc(50% - 96px);
    /* height: 100px; */

    vertical-align: middle;
    box-sizing: border-box;

}

.about_section .about_content .about_block .associated_wrapper img:nth-child(2n-1) {
    padding-left: 0px;

}


.about_section .about_content .about_block .associated_wrapper span.associate {
    color: #fff;
    font-size: 1.5rem;
    padding-bottom: 8px;
}

.about_section .about_block p {
    font-size: 1.4rem;
    letter-spacing: 0.02rem;
    font-weight: 300;
    color: #f1f1f1;
    margin: 10px 0;
}



/* ------------------------------------ */

.connection_caption h3 {
    color: #fff;
    font-size: 2.5rem;
    margin: 2rem 0;
    font-weight: 300;
}

.connection_caption h3 a,
.connection_caption h3 span {
    text-decoration: none;
    color: #fff;
    font-weight: 400;
    border-bottom: 1px solid;
}

.connect_link_wrapper a {
    color: #fff;
    text-decoration: none;
    margin-right: 10px;
    font-size: 1.1rem;
    letter-spacing: 0.15rem;
}

.close_about {
    position: absolute;
    right: 0px;
    bottom: 0px;
    font-size: 1.2rem;
    color: #fff;
}

/* ----------------------- Work ------------------------- */

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
}

.scroller {
    position: absolute;
    white-space: nowrap;
    padding-left: 20vw;
    margin-right: -20vw;

    will-change: transform;
}

.w-block {
    position: relative;
    display: inline-block;
    width: 55vw;
    /* height: 55vw; */
    height: calc(100vh - 35px);
    /* overflow: hidden; */
    align-items: center;
    justify-content: center;
    margin-right: -4px;
    pointer-events: none;
}

.w-block:last-child {
    margin-right: 0;
}

.w-block .w-image {
    position: absolute;
    overflow: hidden;
    pointer-events: all;
    background-color: var(--primary);

    width: 100%;
    height: 100%;

    max-width: 320px;
    max-height: 320px;

    top: 50%;
    left: 50%;

    transform-origin: center;
    transform: translate(-50%, -50%) rotate(45deg);
}


.w-block .w-image img {
    position: absolute;
    width: fit-content;
    left: -62%;
    top: -62%;
    filter: grayscale(1);
    transform: rotate(-45deg) scale(0.75);
    transition: filter 0.2s ease-out;
}



.w-block:hover .w-image img {
    filter: grayscale(0.3);
}

.w-block .w-image .w-bdr {
    position: relative;
    margin: 4%;
    width: 92%;
    height: 92%;
    mix-blend-mode: soft-light;
    border: 0px solid #fff;
    box-sizing: border-box;
    transition: border 0.12s ease-out;
}

.w-block:hover .w-image .w-bdr {
    border: 6px solid var(--primary)
}



.w-block span.w-client {
    pointer-events: all;
    position: absolute;
    bottom: 55%;
    left: 10vw;
    color: var(--primary);
    font-size: 3.2rem;
    line-height: 4.2rem;
    font-family: 'Kumar One', cursive;
    text-align: left;
    -webkit-text-stroke: 0px var(--primary);
    transition: all 0.1s ease-out;
}

.w-block:hover span.w-client {
    -webkit-text-stroke: 2px var(--primary);
    color: transparent;

}

.w-block .w-info {
    /* pointer-events: none; */
    pointer-events: auto;
    position: absolute;
    top: 68%;
    left: 42%;
    width: 60%;
}

.w-block .w-info div {
    display: flex;
    flex-flow: row;
}


.w-block .w-info div span {
    display: block;
    color: var(--primary);
    font-size: 1.3rem;
    font-weight: 500;
    width: 50%;
    padding: 0px 5px;
    box-sizing: border-box;
}

.w-block .w-info div span:first-child {
    color: #efefef;
    font-weight: 300;
    text-align: right;
    padding-right: 10px;

}

canvas {
    position: fixed;
    z-index: -10;
    top: 0;
    left: 0;
}

@media (max-width: 1280px) {

    .w-block {
        width: 60vw;
        /* height: 60vw; */
    }

    .w-block .w-image {
        max-width: 320px;
        max-height: 320px;
    }

    .w-block span.w-client {
        left: 5%;

    }


    .about_section .about_content .about_block .associated_wrapper {
        position: relative;
        display: grid;
        grid-template-columns: 50% 50%;

        width: 100%;
        padding-top: 18px;
    }

    .about_section .about_content .about_block .associated_wrapper span.associate:last-child {
        display: none;
    }
}


@media (orientation: portrait) {

    main.about {
        overflow: scroll;
    }

    main.about .container {
        position: relative;
        height: auto;
        padding-bottom: 60px;
    }



    main.about nav,
    main nav {

        top: auto;
        right: 50%;
        bottom: 15px;
        transform: translate(50%, 0);

        width: calc(100% - 30px);
        justify-content: center;
        background-color: #111111aa;
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
    }

    main.work nav {
        bottom: auto;
        right: 50%;
        top: 15px;

    }


    .wrapper_about {
        align-items: initial;
        width: calc(100vw - 30px);
        height: calc(100vh - 105px);
        height: auto;
        padding: 15px;
        overflow: scroll;
    }

    .about_section .about_content {
        flex-direction: column;
    }

    .about_section .about_content .about_block,
    .about_section .about_content .about_block:last-child {
        width: 100%;
    }

    .about_section {
        justify-content: flex-start;
        align-items: flex-start;
        height: auto;
        overflow: initial;
    }

    .about_section .about_content .about_block h3 {
        margin-top: 20px;
    }
}

@media (orientation: portrait) and (max-width: 640px) {

    .w-block .w-image {
        max-width: 280px;
        max-height: 280px;
    }

    .heading {
        top: 15%;
    }

    .heading h2 {
        font-size: 7vw;
        line-height: normal;
    }

    /* --------------------- */



    .about_section p {
        font-size: 1.0rem;
    }

    .connection_caption h3 {
        font-size: 2rem;
    }

    /* --------------------- */

    .scroller {
        padding-left: 0vw;
        margin-right: 0vw;
        white-space: normal;

    }

    .w-block {
        width: calc(100vw - 30px);
        height: 80vh;
    }

    .w-block .w-image img {
        left: 0;
        top: 0;
        transform: rotate(-45deg) scale(1.4);
        width: -webkit-fill-available;
    }


    .w-block span.w-client {
        width: 100%;
        bottom: 60%;
        font-size: 3rem;
        left: 0;
        transform: translate(0%, 0);
        text-align: center;
    }

    .w-block .w-info {
        top: 75%;
        width: 100%;
        left: 0%;
        transform: translate(0%, 0);
    }

    .w-block .w-info div span {
        font-size: 1.1rem;
    }
}